import React from 'react'
import ReactDOM from 'react-dom'

interface IProps { }

interface IState {
}

class Example extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
    }

    render() {
        return (<>
            <h1>Welcome to the FECbook</h1>
            <p>The FECbook is an online directory that shows people's financial contributions to political candidates and campaigns.</p>
            <p>
                You can use the FECbook to:
                <ul>
                    <li>Search for people in your state running for Congress</li>
                    <li>Find out who donated to your favourite political campaign</li>
                    <li>Look up the contributions made by employees of a certain company</li>
                    <li>See a visualisation of a candidate's political spending</li>
                </ul>
            </p>
            </>)
    }
}


ReactDOM.render(
  <Example />,
  document.getElementById('root')
);

